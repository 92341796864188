<template>
  <div class="wrap-Main">
    <div class="box-S4">
      <div class="N-Page">
        <div class>
          <span class="T-size-24">Alcohol, Temperature and Blood Pressure Test</span>
        </div>
      </div>
    </div>
    <div class="box-S4">
      <table style="width: 100%;">
        <thead>
          <tr>
            <th>Health Parameters</th>
            <th>1st Record</th>
            <th>2nd Record</th>
            <th>3rd Record</th>
            <th>Nurse Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1. Temperature (Celsius °C)</td>
            <td align="center">
              <v-btn v-if="trf_temperature1 == null" class="theme-btn-even"
                @click="OepnDialogAddNurseTest(11, 'Temperature #1')">
                <span>Test 1</span>
              </v-btn>
              <div v-if="trf_temperature1 != null">
                <span>{{ trf_temperature1 }}</span>
              </div>
            </td>
            <td align="center">*</td>
            <td align="center">*</td>
            <td align="center">
              <div v-if="trf_temperature_result == 'Passed'"
                class="box-S1 flex-between-center T-size-18 boxstatuspassed">
                <b>Passed</b>
              </div>
              <div v-else class="box-S1 flex-between-center T-size-18 boxstatusfailed">
                <b>Failed</b>
              </div>
            </td>
          </tr>
          <tr>
            <td>2. Blood Pressure (BP)</td>
            <td align="center">
              <v-btn v-if="trf_blood_pressure1 == null" class="theme-btn-even"
                @click="OepnDialogAddNurseTest(21, 'Blood Pressure #1]')">
                <span>Test 1</span>
              </v-btn>
              <div v-if="trf_blood_pressure1 != null">
                <span>{{ trf_blood_pressure1 }}</span>
              </div>
            </td>
            <td align="center">
              <v-btn v-if="trf_blood_pressure2 == null" :disabled="trf_blood_pressure1 == null"
                v-show="trf_blood_pressure_result != 'Passed'" class="theme-btn-even"
                @click="OepnDialogAddNurseTest(22, 'Blood Pressure #2')">
                <span>Test 2</span>
              </v-btn>
              <div v-if="trf_blood_pressure2 != null">
                <span>{{ trf_blood_pressure2 }}</span>
              </div>
            </td>
            <td align="center">
              <v-btn v-if="trf_blood_pressure3 == null" :disabled="trf_blood_pressure2 == null"
                v-show="trf_blood_pressure_result != 'Passed'" class="theme-btn-even"
                @click="OepnDialogAddNurseTest(23, 'Blood Pressure #3)')">
                <span>Test 3</span>
              </v-btn>
              <div v-if="trf_blood_pressure3 != null">
                <span>{{ trf_blood_pressure3 }}</span>
              </div>
            </td>
            <td align="center">
              <div v-if="trf_blood_pressure_result == 'Passed'"
                class="box-S1 flex-between-center T-size-18 boxstatuspassed">
                <b>Passed</b>
              </div>
              <div v-else class="box-S1 flex-between-center T-size-18 boxstatusfailed">
                <b>Failed</b>
              </div>
            </td>
          </tr>
          <tr>
            <td>3. % Blood Alcohol Concentration (%BAC)</td>
            <td align="center">
              <v-btn v-if="trf_blood_alcohol1 == null" class="theme-btn-even"
                @click="OepnDialogAddNurseTest(31, 'Blood Alcohol Concentration #1')">
                <span>Test 1</span>
              </v-btn>
              <div v-else>
                <span>{{ trf_blood_alcohol1 }}</span>
              </div>
            </td>
            <td align="center">
              <v-btn v-if="trf_blood_alcohol2 == null" :disabled="trf_blood_alcohol1 == null"
                v-show="trf_blood_alcohol_result != 'Passed'" class="theme-btn-even"
                @click="OepnDialogAddNurseTest(32, 'Blood Alcohol Concentration #2')">
                <span>Test 2</span>
              </v-btn>

              <div v-if="trf_blood_alcohol2 != null">
                <span>{{ trf_blood_alcohol2 }}</span>
              </div>
            </td>
            <td align="center">*</td>
            <td align="center">
              <div v-if="trf_blood_alcohol_result == 'Passed'"
                class="box-S1 flex-between-center T-size-18 boxstatuspassed">
                <b>Passed</b>
              </div>
              <div v-else class="box-S1 flex-between-center T-size-18 boxstatusfailed">
                <b>Failed</b>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
      <div class="box-S4">
        <div class="B-carddetail B-formMain">
          <div class="b-row">
            <div class="box-S4">
              <div class="B-carddetail center Bsize190" style="padding-top:17px;">
              </div>
              <div class="B-carddetail center Bsize190" style="padding-top:17px;"
                v-show="nurseStatusHealthScreenForm != 'No Data'" @click="PrintNurseTest(1)">
                <span class="T-size-18">View</span>
                <v-icon style="padding-left: 5px">mdi-file-document-outline</v-icon>
              </div>
              <div class="B-carddetail center Bsize190" style="padding-top:17px;"
                v-show="nurseStatusHealthScreenForm != 'No Data'" @click="PrintNurseTest(2)">
                <span class="T-size-18">Download</span>
                <v-icon style="padding-left: 5px">mdi-tray-arrow-down</v-icon>
              </div>
              
            </div>
          </div>
        </div>
      </div>


    <v-dialog v-model="dialogAddNurseTest" persistent max-width="800" fullscreen>
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="box-S4 flex-between-center">
            <div class="N-Page T-size-36 TF">{{ dialogAddNurseTestTitle }}</div>
          </div>
          <div class="b-content">
            <div class="t-des">
              <div class="B-carddetail B-formMain">
                <div class="b-row">
                  <div class="box-S4">
                    <v-text-field class="hideMessage" label="*Value" type="text" v-model="result">
                    </v-text-field>
                  </div>
                </div>
                <div class="b-row">
                  <div class="box-S4">
                    <v-select class="hideMessage" v-model="nurseStatus" :items="itemsListNurseStatus" label="*Status"
                      item-text="ns_name" item-value="ns_id" return-object></v-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-S4"></div>

            <div class="box-btn">
              <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogAddNurseTest = false">
                <span class="T-size-18">cancel</span>
              </v-btn>
              <v-btn class="theme-btn-even btn-save Bsize100" text @click="OpenDialogConfirmSaveNurseTest()">
                <span class="T-size-18">Save</span>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogConfirmSaveNurseTest" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog save">
            <div class="t-title">{{dialogAddNurseTestTitle}}</div>
            <div class="t-des">
              <div class="T-size-36">{{ result }}</div>
              <div class="boxstatuspassed" v-if="nurseStatus != null && nurseStatus.ns_name == 'Passed'"><b>{{ nurseStatus.ns_name }}</b></div>
              <div class="boxstatusfailed" v-if="nurseStatus != null && nurseStatus.ns_name == 'Failed'"><b>{{ nurseStatus.ns_name }}</b></div>
            </div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogConfirmSaveNurseTest = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" text @click="SaveNurseTest()">
              <span class="T-size-18">Confirm</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { throws } from "assert";
import feathersClientUOA from "../../plugins/feathers-client-uoa";
import { Nurseform } from "../../utils/NurseForm";

export default {
  data: () => ({
    passId: null,
    result: null,
    nurseStatus: null,
    index: null,
    itemsListNurseStatus: [
      { ns_id: 1, ns_name: "Passed" },
      { ns_id: 2, ns_name: "Failed" },
    ],
    dialogAddNurseTest: false,
    dialogAddNurseTestTitle: "",
    dialogConfirmSaveNurseTest: false,
    index: "",

    trf_temperature1: null,
    trf_temperature_result: null,

    trf_blood_pressure1: null,
    trf_blood_pressure2: null,
    trf_blood_pressure3: null,
    trf_blood_pressure_result: null,

    trf_blood_alcohol1: null,
    trf_blood_alcohol2: null,
    trf_blood_alcohol_result: null,
  }),
  watch: {

  },
  props: ["passengerID"],
  async mounted() {
    this.passId = this.passengerID;
    await this.RenderUI();
  },
  methods: {
    async RenderUI() {
      try {
        var q = {};
        q.p_id = this.passId;
        var resFind = await feathersClientUOA.service("mubadala-test-record-form").find({ query: q });
        this.trf_temperature1 = resFind.data[0].trf_temperature1;
        this.trf_temperature_result = resFind.data[0].trf_temperature_result;

        this.trf_blood_pressure1 = resFind.data[0].trf_blood_pressure1;
        this.trf_blood_pressure2 = resFind.data[0].trf_blood_pressure2;
        this.trf_blood_pressure3 = resFind.data[0].trf_blood_pressure3;
        this.trf_blood_pressure_result = resFind.data[0].trf_blood_pressure_result;

        this.trf_blood_alcohol1 = resFind.data[0].trf_blood_alcohol1;
        this.trf_blood_alcohol2 = resFind.data[0].trf_blood_alcohol2;
        this.trf_blood_alcohol_result = resFind.data[0].trf_blood_alcohol_result;
      } catch (error) {
        console.log(error);
      }
    },

    async PrintNurseTest(type) {
      Nurseform.PrintTestRecordForm(this.passengerID, type)
    },

    async OepnDialogAddNurseTest(index, title) {
      this.dialogAddNurseTestTitle = "";
      this.index = "";

      this.dialogAddNurseTestTitle = title;
      this.index = index;
      this.dialogAddNurseTest = true;
      this.result = null;
      this.nurseStatus = null;
    },

    OpenDialogConfirmSaveNurseTest(){
      if(this.result != null && this.nurseStatus != null){
        this.dialogConfirmSaveNurseTest = true;
      }
    },

    async SaveNurseTest() {
      let q = {};
      q.p_id = this.passId;
      let res = await feathersClientUOA.service("mubadala-test-record-form").find({ query: q });
      if (res.total == 1) {
        let trf_id = res.data[0].trf_id;
        let dataUpdate = {};
        if (this.index == 11) {
          dataUpdate.trf_temperature1 = this.result;
          dataUpdate.trf_temperature_result = this.nurseStatus.ns_name;
        }

        if (this.index == 21) {
          dataUpdate.trf_blood_pressure1 = this.result;
          dataUpdate.trf_blood_pressure_result = this.nurseStatus.ns_name;
        }

        if (this.index == 22) {
          dataUpdate.trf_blood_pressure2 = this.result;
          dataUpdate.trf_blood_pressure_result = this.nurseStatus.ns_name;
        }

        if (this.index == 23) {
          dataUpdate.trf_blood_pressure3 = this.result;
          dataUpdate.trf_blood_pressure_result = this.nurseStatus.ns_name;
        }

        if (this.index == 31) {
          dataUpdate.trf_blood_alcohol1 = this.result;
          dataUpdate.trf_blood_alcohol_result = this.nurseStatus.ns_name;
        }

        if (this.index == 32) {
          dataUpdate.trf_blood_alcohol2 = this.result;
          dataUpdate.trf_blood_alcohol_result = this.nurseStatus.ns_name;
        }

        try {
          await feathersClientUOA.service("mubadala-test-record-form").patch(trf_id, dataUpdate);
        } catch (error) {
          console.log(error);
        }

        this.RenderUI();
        this.dialogAddNurseTest = false;
        this.dialogConfirmSaveNurseTest = false;
      }else{
        alert("E-Form เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง")
      }
    }
  },
};
</script>

<style scoped>
.btn-checkin {
  background-color: #6c63ff !important;
}

.btn-viewcheckin {
  background-color: #00ba48 !important;
}

.btn-delete {
  background-color: #ff0001 !important;
}

td {
  padding: 7px;
}

th {
  padding: 10px;
  color: red;
}

.boxstatuspassed {
  color: white;
  background-color: green;
  border-radius: 10px;
  text-align: center;
  width: 100px;
  height: 25px;
  justify-content: center;
  margin: auto;
  width:90%;
}

.boxstatusfailed {
  color: white;
  background-color: red;
  border-radius: 10px;
  text-align: center;
  width: 100px;
  height: 25px;
  justify-content: center;
  margin: auto;
  width:90%;
}
</style>